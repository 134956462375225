import React from 'react';
import {Page, Grid, Text, Decor, Input, List, Link} from '../../components';
import s from '../../locale/contact/s.json';

import styles from './Contact.module.scss';

const GOOGLE_SHEETS_WEB_APP_URL = 'https://script.google.com/macros/s/AKfycbzj_9XCKeMa-We01h0OIunHgFBqNyecK-M9p_dm/exec';

interface Props {
  t?: any;
}
interface State {
  message: string,
  color: string,
}

type ComposedProps = Props;

export default class Contact extends React.PureComponent<ComposedProps, State> {
  state = {message: '', color: 'black'};

  sendForm = (e) => {
    e.preventDefault();
    const form = e.target;
    this.setState({message: 'requestMessage'})
    if (form._check.value === '') {
      fetch(GOOGLE_SHEETS_WEB_APP_URL, {
        method: 'POST',
        body: new FormData(form),
      })
      .then(() => this.setState({message: 'successMessage', color: 'green'}))
      .catch(() => this.setState({message: 'errorMessage', color: 'red'}))
      .finally(() => form.reset());
    }
  };

  render() {
    const {message, color} = this.state;
    const t = this.props.t || (() => []);

    return (
      <Page title="Contact">
        <Page.Layout>
          <Page.Section>
            <Text tag="h1" align="center">{t(s.contactTitle)}</Text>
            <Decor.Line></Decor.Line>
            {this.state.message
              ? <Text size="h3" align="center" color={color}>{t(s[message])}</Text>
              : (
                <Grid>
                  <form name={`${t(s.contactTitle)}`} onSubmit={this.sendForm}>
                    <input type="hidden" name="_check" value="" />
                    <Input type="text" label={`${t(s.labelName)}`} placeholder={`${t(s.labelNamePlaceholder)}`} id="name" name="name"></Input>
                    <Input type="email" label={`${t(s.contactEmail)}`} placeholder="example@email.com" id="email" name="email"></Input>
                    <Input type="text" label={`${t(s.labelMessage)}`} id="message" placeholder={`${t(s.labelMessagePlaceholder)}`} name="message" textarea></Input>
                    <input className={styles.FormSubmit} type="submit" value={`${t(s.contactSubmit)}`} />
                  </form>
                </Grid>
              )
            }
          </Page.Section>
          <Page.Section>
            <Grid>
              <Grid.ScreenWidth>
                <div className={styles.LocationContainer}>
                  <div className={styles.LocationInfo}>
                    <Text.Container>
                      <Text tag="h3" size="h4" uppercase>{t(s.corporateAddress)}</Text>
                      <List noBullets noPadding>
                        <List.Item>Food Empire Holdings</List.Item>
                        <List.Item>31 Harrison Road, #08-01</List.Item>
                        <List.Item>Food Empire Building</List.Item>
                        <List.Item>Singapore, 369649</List.Item>
                      </List>
                    </Text.Container>
                    <Text.Container>
                      <Text tag="h3" size="h4" uppercase>Food Empire</Text>
                      <List noBullets noPadding>
                        <List.Item>{t(s.contactEmail)}<Link aTag unstyled to="mailto:info@foodempire.com">info@foodempire.com</Link></List.Item>
                        <List.Item>{t(s.contactWebsite)}<Link aTag unstyled to="https://www.foodempire.com">www.foodempire.com</Link></List.Item>
                      </List>
                    </Text.Container>
                    <Text.Container>
                      <Text tag="h3" size="h4" uppercase>CafePho Canada</Text>
                      <List noBullets noPadding>
                        <List.Item>{t(s.contactEmail)} <Link aTag unstyled to="mailto:ajayhanda@foodempire.com">ajayhanda@foodempire.com</Link></List.Item>
                        <List.Item>{t(s.contactPhone)} +1 403 389 1724</List.Item>
                      </List>
                    </Text.Container>
                  </div>
                  <div className={styles.LocationMap}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7324322922896!2d103.88305351475402!3d1.336790799025024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1791f5271c5d%3A0xf4b5aa2e3980ec73!2s31%20Harrison%20Rd%2C%20Singapore%20369649!5e0!3m2!1sen!2sco!4v1572298795460!5m2!1sen!2sco"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{border: 0}}
                      allowFullScreen={true}
                    />
                  </div>
                </div>
              </Grid.ScreenWidth>
            </Grid>
          </Page.Section>
        </Page.Layout>
      </Page>
    );
  }
}
